import { useState, useEffect, useRef } from 'react'
import Map from './components/Map'
import Location from './components/Location'
import Toggle from './components/Toggle'
import data from './data'


function App() {
  const [splitView, setSplitView] = useState(false)
  const [location, setLocation] = useState(null)
  const [isDataMode, setIsDataMode] = useState(false)
  const audioRef = useRef(null)
  const firstPlay = useRef(false)
  
  useEffect(() => {
    if (location !== null) {
      setSplitView(true)
    } else {
      setSplitView(false)
    }
  }, [location])

  return (
    <div className='app'>

      <header>
        <h1> compositions.nyc</h1>
      </header>

      <aside>
        <p>
        Compositions explores the distance between the New York we experience and the New York we imagine through data.
        </p>
        <p>
        Using NYC Open Data and a small library of stock sound clips, we algorithmically create soundscapes for 15 locations throughout New York City.
        </p>
        <p>
        To start, select a site on the map, then use the toggle to switch between the field recording and the data-generated soundscape.
        </p>
        <Toggle offLabel='Field' onLabel='Data' state={isDataMode} onChange={e =>  setIsDataMode(e)} />
        <audio ref={audioRef} src='/assets/1-second-of-silence.mp3'/>
      </aside>

      <main className={splitView ? 'split' : ''}>
        <Map
          isActive={location !== null}
          onChange={(i) => { 
            if(!firstPlay.current) {
              audioRef.current.play()
              firstPlay.current = true
            }
            setLocation(i) 
          }}
        />

        <div className='info'>
          {
            data.map((item, index) => {
              return (
                <Location
                  data={item}
                  isDataMode={isDataMode}
                  isActive={location === index}
                  onClose={() => setLocation(null)}
                  key={index}
                />
              )
            })
          }
        </div>
      </main>


      <footer>
        <p> A <a href='https://datathroughdesign.com/'>Data Through Design 2021</a> project 
          by <a href='https://juliabloomportfolio.com/'>Julia Bloom</a>,&nbsp; 
          <a href='https://everythingisreally.com/'>Michael Hsu</a>,
          and <a href='https://playdo.io/'>Sukanya Aneja</a>. 
        </p>
      </footer>

    </div>
  );
}

export default App
