import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import About from './About'
import App from './App'
import './styles/main.scss'

ReactDOM.render(
  <React.StrictMode>
    
    <Router>
      <ScrollToTop />
      
      <Switch>
        
        <Route path="/about">
          <About />
        </Route>
        
        <Route path="/">
          <App />
        </Route>

      </Switch>
    </Router>

  </React.StrictMode>,
  document.getElementById('root')
)