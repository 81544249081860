import { useEffect, useRef } from 'react'
import CrossIcon from './CrossIcon'

function Location({ data, isDataMode, isActive, onClose, ...props }) {
  const dataSound = useRef(null)
  const fieldSound = useRef(null)

  useEffect(() => {
    if (dataSound) {
      dataSound.current.src = `${data.folder}/data.mp3`
      dataSound.current.load()
    }
  }, [data, dataSound])

  useEffect(() => {
    if (dataSound) {
      fieldSound.current.src = `${data.folder}/field.mp3`
      fieldSound.current.load()
    }
  }, [data, fieldSound])


  useEffect(() => {
    if (fieldSound.current !== null && dataSound.current !== null && isActive) {
      if (isDataMode) {
        fieldSound.current.pause()
        dataSound.current.play()
          .then(a => console.log('played', a))
          .catch(e => console.log(e))
      } else {
        dataSound.current.pause()
        fieldSound.current.play()
          .then(a => console.log('played', a))
          .catch(e => console.log(e))
      }
    } else {
      fieldSound.current.pause()
      dataSound.current.pause()
    }
  }, [isActive, isDataMode])

  function close() {
    if (isDataMode) {
      dataSound.current.pause()
    } else {
      fieldSound.current.pause()
    }
    onClose()
  }

  return (
    <div className={`location ${isActive ? '-active' : ''}`}>
      <div className='location__header'>
        <h2> {data.neighborhood} </h2>
        <div className='close__button' onClick={close}>
          <CrossIcon />
        </div>
      </div>

      <div className='location__content'>
        <div className='location__text'>
          {
          (isDataMode ? data.text.data : data.text.field).map( (text, i) => {
            return <p key={i}> { text } </p>
          })
          }
        </div>
        <img src={`${data.folder}/${isDataMode ? 'data.png' : 'field.png'}`} alt={data.name} />
      </div>

      <audio
        ref={e => dataSound.current = e}
        loop
        preload='auto'
      >
      </audio>
      <audio
        ref={fieldSound}
        loop
        preload='auto'
      >
      </audio>
    </div>
  )
}

export default Location