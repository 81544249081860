import { useState, useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'
import data from '../data'

function Map({ isActive, onChange, ...props}) {
  const map = useRef(null)
  const mapEl = useRef(null)
  const markerEls = useRef([])
  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    if (!!mapEl && markerEls.current.length > 0) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX
      const bounds = { lat: { min: Infinity, max: -Infinity }, lng: { min: Infinity, max: -Infinity } }
      data.forEach(point => {
        bounds.lat.min = Math.min(point.latitude, bounds.lat.min)
        bounds.lat.max = Math.max(point.latitude, bounds.lat.max)
        bounds.lng.min = Math.min(point.longitude, bounds.lng.min)
        bounds.lng.max = Math.max(point.longitude, bounds.lng.max)
      })

      map.current = new mapboxgl.Map({
        container: mapEl.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        bounds: [
          [bounds.lng.min, bounds.lat.min],
          [bounds.lng.max, bounds.lat.max],
        ],
        fitBoundsOptions: {
          padding: 50,
        }
      })

      map.current.on('load', function () {
        map.current.getStyle().layers.forEach( layer => {
          if (layer.type === 'symbol') {
            map.current.removeLayer(layer.id);
          }
        })
      })

      data.forEach((point, index) => {
        new mapboxgl.Marker(markerEls.current[index])
          .setLngLat(new mapboxgl.LngLat(point.longitude, point.latitude))
          .addTo(map.current)
      })
    }
  }, [mapEl, markerEls])

  useEffect(() => {
    if (!isActive) markerEls.current.forEach(el => el && el.classList.remove('-active')) //yuck
    // TODO: hack to resize after transition is over.. fix this
    setTimeout(() => {
      map.current.resize();
    }, 210)
  }, [isActive])

  function onMarkerClick(event) {
    const index = parseInt(event.target.dataset.index)
    const newIndex = index === activeIndex ? null : index
    if (activeIndex !== null) markerEls.current[activeIndex].classList.remove('-active')
    if (newIndex !== null) markerEls.current[newIndex].classList.add('-active')
    onChange(newIndex)
    setActiveIndex(newIndex)
  }

  return (
    <>
      <div className='map' ref={mapEl}></div>

      <div className='markerTemplates'>
        {
          data.map((point, index) => {
            return (
              <div
                className='marker'
                ref={element => markerEls.current.push(element)}
                key={index}
                onClick={onMarkerClick}
                data-index={index}
              >
                {/* { point.name} */}
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default Map