import { useState, useEffect } from 'react'

function Toggle({onLabel, offLabel, state, onChange, ...props}) {
  const [isOn, setIsOn] = useState(state)

  useEffect(() => {
    setIsOn(state)
  }, [state])

  function onClick() {
    const newState = !isOn
    setIsOn(newState)
    if (typeof onChange === 'function') onChange(newState)
  }

  return (
    <div className={`toggle ${isOn ? '-on' : '-off'}`} onClick={onClick}>
      <p className={`toggle__label ${!isOn ? '-active' : ''}`}> {offLabel} </p>
      <div className='toggle__button'>
        <div className='toggle__part -left'></div>
        <div className='toggle__part -right'></div>
      </div>
      <p className={`toggle__label ${isOn ? '-active' : ''}`}> {onLabel} </p>
    </div>
  )
}

export default Toggle