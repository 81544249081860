import { useEffect } from "react"
import { useLocation } from "react-router-dom"

function ScrollToTop() {
  const { pathname, hash } = useLocation()

  useEffect(() => {
    if (hash === '') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: pathname === '/' ? 'smooth' : 'instant'
      })
    }
  }, [pathname, hash])

  return null
}

export default ScrollToTop