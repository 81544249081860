// TODO: paths are annoying.. need the prefix in order to work with gh-pages, i think

const data = [
  {
    neighborhood: "harlem",
    latitude: 40.80938696,
    longitude: -73.94908988,
    borough: "manhattan",
    folder: '/assets/harlem/',
    text: {
      field: [
        `Recorded the day immediately after an unusually heavy amount of snowfall, the commercial street chosen for Harlem’s field recording was nonetheless packed with pedestrian shoppers and commuters. The revving of passing trucks are particularly loud in the recording, as many cars were stopped in heavy traffic due to it being close to rush hour. Comparing this recording with the generated soundscape, listeners may notice how the algorithm greatly exaggerated the sounds of construction, which are almost entirely absent from the field recording.
        `
      ],
      data: [
        `Though the data used for Harlem did not capture any of the neighborhood’s many outdoor cafes, the sounds of passing footsteps and snippets of conversation from a moderate number of pedestrians can be heard throughout the generated soundscape. Listeners will also likely notice the frequency of honking, idle engines, and truck horns peppered throughout the soundscape.`,
        `The data records far more street construction projects in Harlem relative to other sites. As a result, Harlem’s soundscape is a stream of frequent drilling, jack-hammering, and metal clanging noises. In reality, the schedules of so many construction projects are seldom likely to align perfectly, but the low time-resolution of the data means that all construction is represented as happening simultaneously.`
      ],
    }
  },
  {
    neighborhood: "bergen st",
    latitude: 40.68613523,
    longitude: -73.99117003,
    borough: "brooklyn",
    folder: '/assets/bergen/',
    text: {
      field: [
        `Most noticeable in the field recording for Bergen street are the sounds of pedestrian footsteps and passing conversation. Children talking and playing can be heard clearly in the field recording here, which were not sounds included as ingredients for the generated soundscapes. The sounds of helicopters are also common throughout the field recording, though the artists had no data to use towards the generation of such a noise. The sounds of plates clattering and dogs barking and panting in this field recording are surprisingly well correlated with what is represented in the data.`
      ],
      data: [
        `The data near Bergen St. show high levels of “nuisance” sounds relative to other sites, most of which are complaints about dogs barking and loud party music. More than any other location one can hear canine yips, panting, and the jingling of dog tags. Occasionally, a muffled beat and the distant clamor of an indoor party can be heard. While the levels of pedestrian traffic are lower at Bergen St. as compared to other sites, an elevated number of outdoor cafes added the imagined sounds of clinking glasses, cutlery, and excited conversation of people sharing a meal.`
      ],
    }
  },
  {
    neighborhood: "times square",
    latitude: 40.75815725,
    longitude: -73.98538861,
    borough: "manhattan",
    folder: '/assets/times_square/',
    text: {
      field: [
        `The pedestrian data for Times Square, collected over two years ago, suggested that the area should be filled with throngs of people. However, on the day of recording (February, in the middle of Covid-19 pandemic), only a few pedestrians lingered in the empty plaza. Instead, the field recording for Times Square features a moderate amount of construction and traffic sounds, which agree with the generated soundscape. In the distance can be heard the occasional shouting and whistling of a hotel worker protest, which were not sounds used in the generated soundscape. Also prominent in the field recording are police sirens, which are underrepresented in the 311 noise complaints in this area. Whether this is due to the lack of residences in this area, or the current residents’ high tolerance for noise is impossible to know.`
      ],
      data: [
        `Unsurprisingly, the data for Times Square suggests a more dense and urban soundscape than most of the other chosen locations. High levels of pedestrian traffic, street traffic, and major construction projects are present, as well as a moderate number of outdoor cafes. Taken together, the volume of pedestrian shoes clicking the pavement, horns honking, underground trains, jackhammering, and loud conversation strongly evoke the daily bustle of a dense city. However, listeners may be surprised to hear the rare chirping of birds and the occasional squirrel, which the algorithm included due to the tiny amount of greenery captured in the data.`
      ],
    }
  },
  {
    neighborhood: "upper east side",
    latitude: 40.77378443,
    longitude: -73.96604814,
    borough: "manhattan",
    folder: '/assets/upper_east/',
    text: {
      field: [
        `As in the generated soundscape, the most prevalent sound in the Upper East Side’s field recording are the sounds of birds chirping. Recorded on a cold, snowy day during which many pedestrians may have opted to stay home, the rare crunch of footsteps and snippets of conversation can still be heard throughout the recording. Noticeably lacking from the field recording however are the predicted levels of construction sounds, which were relatively high in the data.`
      ],
      data: [
        `As the site with the highest amount of overall greenery in the data, the generated soundscape of the Upper East Side consists mostly of chirping birds and chattering squirrels. Contributing to this particular soundscape’s sense of tranquility are the overall lower occurrences of pedestrian, vehicle, and cafe noises, as well as the absence of any “nuisance” sounds generated from 311 noise complaints. There were, however, a surprisingly large number of construction projects in the surrounding area, leading to the juxtaposition of nature sounds against jack-hammers and heavy machinery in the generated soundscape, a combination which would seem unlikely to occur in the real world.`
      ],
    }
  },
  {
    neighborhood: "orchard st",
    latitude: 40.71813231,
    longitude: -73.9902652,
    borough: "manhattan",
    folder: '/assets/orchard/',
    text: {
      field: [
        `Though the field recording for Orchard St was not taken during a particularly busy lunchtime rush, the sounds of diners and clinking glass can still be heard in the background. The “nuisance” noises of 311 complaints regarding loud parties and car alarms do not make an appearance in the field recording. Whether this is due to the recording being taken during the day, the active “stay at home” order for New Yorkers due to the Covid 19 pandemic, or the misleading nature of 311 complaints themselves is unclear.`
      ],
      data: [
        `With a very high number of outdoor restaurants and a moderate amount of pedestrian traffic, the generated soundscape for Orchard St. may give the impression of a festive crowd. The constant clinking of glasses, hum of animated conversation, and persistent tapping of footsteps on pavement signal to the high volume of people nearby. With a large number of construction projects recorded in the data, the occasional cacophony of drilling and jack-hammering is also not lost among the sounds of people talking.`,
        `Most impressive however, is the extremely high number of 311 noise complaints, the bulk of which can be heard within the soundscape as loud music emanating from private parties and cars, and car alarms. While the real-life frequency of these “nuisance” sounds are likely over-exaggerated as heard in soundscape, they also blend easily against the hubbub of diners and passing pedestrians to create an overall festive and bustling atmosphere.`
      ],
    }
  },
  {
    neighborhood: "williamsburg",
    latitude: 40.71737076,
    longitude: -73.95827679,
    borough: "brooklyn",
    folder: '/assets/williamsburg/',
    text: {
      field: [
        `Despite being located near the busy Bedford Ave subway stop, the Williamsburg location was relatively empty on recording day. The nearby shelters constructed for diners to eat in during the colder months of the Covid-19 pandemic were almost entirely empty, and almost no restaurant sounds can be heard throughout the recorded clip. While the a few passersby can be heard chatting or walking their dogs, the familiar hubbub of a crowded street filled with diners and shoppers is noticeably lost.`
      ],
      data: [
        `While the occasional passing car can be heard throughout Williamsburg’s generated soundscape, it is also relatively lacking in excessive honking and revving truck engines as compared with other locations. Instead, listeners will likely notice the raised volume of pedestrian chatter and restaurant-goer conversation, as well as the frequency of passing footsteps and the clinking of dining plates and glasses. The large number of major construction projects in the area are represented through the sounds of drills, hammers, and metallic bangs, while the majority of Williamsburg’s 311 noise complaints appear in the soundscape as muffled party music and barking dogs.`
      ],
    }
  },
  {
    neighborhood: "bushwick",
    latitude: 40.69973897,
    longitude: -73.91133465,
    borough: "brooklyn",
    folder: '/assets/bushwick/',
    text: {
      field: [
        `Bushwick’s field recording captures that location’s main feature as a major crossroads for pedestrians, vehicles, and trains. While the sounds of pedestrian footsteps are relatively sparse (perhaps explained by the microphone’s location in the middle of a plaza away from the sidewalk), the background chatter of commuters talking on the phone and amongst themselves can clearly be heard. The almost constant idling of bus engines and passing of the above ground M train should also be recognizable to any listeners familiar with the area.`
      ],
      data: [
        `The data captured at the Bushwick site contained lower values across all categories, including the number of pedestrians, vehicle traffic, construction projects, outdoor restaurants, 311 complaints, and overall greenery. The resulting datascape is therefore somewhat sparser than other locations with high values in these categories. Only the loud whirr and shrieking brakes of the two above and below ground subway lines to make up for the occasional silences. Careful listeners may also hear soft squeaking of rats and the rare barking of a dog (both generated using 311 rodent and noise complaints).`
      ],
    }
  },
  {
    neighborhood: "astoria",
    latitude: 40.76210282,
    longitude: -73.9187948,
    borough: "queens",
    folder: '/assets/astoria/',
    text: {
      field: [
        `Taken near the end of the day by an empty church, Astoria’s field recording is filled with the light footsteps of lone pedestrians, the occasional passing cars, and relatively few honking horns. Though the microphone stood close to a quiet yard and a handful of trees, the recording failed to pick up any sounds of birds or other wildlife. Perhaps it was still too cold.`
      ],
      data: [
        `The data captured at the Astoria site contained much lower values for the number of construction projects and outdoor restaurants as compared with other sites. However, the persistent crunching of footsteps, honking of cars, and chirping of birds are frequent enough throughout Astoria’s soundscape to evoke a vibrant, albeit less dense, area of a city. Though the number of 311 complaints are not particularly high in Astoria, the few that were filed for loud barking, idling cars, and loud party music can be heard at some point by attentive listeners.`
      ],
    }
  },
  {
    neighborhood: "flushing",
    latitude: 40.7589375,
    longitude: -73.82999174,
    borough: "queens",
    folder: '/assets/flushing/',
    text: {
      field: [
        `A strange hum, perhaps emanating from a kitchen or nearby heating unit can be heard throughout Flushing’s field recording. Though the datasets used for sound generation contained noise complaints for loud industrial sounds coming from vendors or AC units, we can only guess the real-world cause of such a sound and why it is not represented in the data. The sounds of passing vehicles from the wide, busy street and of the above ground train may also be heard. Over all this noise, however, listeners can still hear the many footsteps and conversations of passing shoppers.`
      ],
      data: [
        `With much higher counts of pedestrian traffic and outdoor restaurants compared to other sites, Flushing’s soundscape is largely dominated by the constant clapping and crunching of many footsteps and the elevated clamor of pedestrian and diner conversation. Every so often, an above ground train roars by and drowns out the hubbub. Drilling, jack-hammering, and the clanging of construction equipment are also commonly heard throughout, illustrating the moderate number of construction projects captured in Flushing’s data.`
      ],
    }
  },
  {
    neighborhood: "brighton beach",
    latitude: 40.57731694,
    longitude: -73.96138638,
    borough: "brooklyn",
    folder: '/assets/brighton_beach/',
    text: {
      field: [
        `Brighton Beach’s field recording begins on the relatively loud side, with the idling of a nearby truck and the passing of the above ground Q train drowning out all other potential sounds. As the track continues, listeners may hear the rare passing of a pedestrian in addition to the occasional honking of horns and hissing of garbage trucks. One might imagine that during the summer months, these sounds of pedestrian traffic skyrocket, as New Yorkers and tourists exiting the train would need to pass this very spot in order to access the boardwalk of Brighton Beach.`
      ],
      data: [
        `Relative to other sites, the data for Brighton Beach contained slightly lower values for pedestrian counts, street traffic, construction projects, and outdoor restaurants. The resulting soundscape may sound only slighter sparser to listeners, however, as there is still a steady stream of footsteps, clangs from construction equipment, and car horns. The rumbling of an above ground subway may also be heard occasionally.`
      ],
    }
  },
  {
    neighborhood: "brighton heights",
    latitude: 40.63824251,
    longitude: -74.07811895,
    borough: "staten island",
    folder: '/assets/brighton_heights/',
    text: {
      field: [
        `Though various clips of construction sounds are represented across most of the generated soundscapes, only at the Brighton Heights location can the distinct sounds of clanging machinery and beeping of construction trucks be clearly heard in the field recording. Since the data for this location actually suggested a very low number of active street and building construction for the area, it’s possible the artists were lucky in choosing a day when the few construction projects were actually active.`
      ],
      data: [
        `The data for Brighton Heights shows a moderate amount of street traffic, while pedestrian counts are very low. This makes for a distinct NYC soundscape where the honking and whooshing of passing cars is much more frequent than the tapping of pedestrian footsteps on the pavement. Very rarely, the distant hubbub of some diners’ conversation may be heard, due to this location’s handful of outdoor cafes. By far the most common sound in this Staten Island soundscape, however, is the chirping of birds and chattering of squirrels, an expression of the higher amounts of greenery in the data.`
      ],
    }
  },
  {
    neighborhood: "richmond hill",
    latitude: 40.58517265,
    longitude: -74.16070004,
    borough: "staten island",
    folder: '/assets/richmond_hill/',
    text: {
      field: [
        `Keeping with Richmond Hill’s status as the site with the least “dense” data, the site’s field recording likewise consists mostly of idling and passing cars. Despite the algorithm’s relative accuracy in depicting the volume of vehicular traffic, there is curiously almost no honking heard in the field recording. While some might attribute this to the suburban area’s more “courteous” neighbors, another possibility is the wider road and lack of overall congestion during the time of recording. Also noticeably missing are the sounds of birds and other wildlife, which were highly present in the generated soundscape.`
      ],
      data: [
        `As the only site without any recorded construction projects, outdoor cafes, or 311 complaints in the dataset, Richmond Hill’s generated soundscape is primarily one of honking horns, passing vehicles, and birds chirping. Very rarely, the crunching footsteps of a lone pedestrian and snippets of brief conversation of a small group may also be heard. Otherwise, Richmond Hill’s generated soundtrack is perhaps the sparsest across all of the chosen locations.`
      ],
    }
  },
  {
    neighborhood: "fordham",
    latitude: 40.86122717,
    longitude: -73.8901107,
    borough: "bronx",
    folder: '/assets/fordham/',
    text: {
      field: [
        `Taken at the entrance gate to Fordham University, the field recording for Fordham consists mostly of engines revving and cars honking from the busy adjacent road. The lower levels of ambient pedestrian noise at the time of recording may also (as in many locations) be attributed to the Covid-19 pandemic, as fewer students would be attending class in-person. Interestingly, the muffled sounds of music can be heard at some point during the recording, mirroring the presence of 311 complaints regarding loud party music in the data. Whether these muffled songs are coming from the passing vehicles, or student’s dorm room however is anyone’s guess.`
      ],
      data: [
        `The data for Fordham shows elevated values for pedestrian foot traffic, vehicle traffic, and greenery in the area. With the absence of any outdoor restaurants nearby, the generated soundscape is primarily dominated by footsteps, honking cars, and chirping birds. Careful listeners may also hear the muffled rumbling of an underground train, as well as the thumping of this area’s only 311 noise complaints, that of loud indoor parties.`
      ],
    }
  },
  {
    neighborhood: "forest hills",
    latitude: 40.71944285,
    longitude: -73.84519879,
    borough: "queens",
    folder: '/assets/forest_hills/',
    text: {
      field: [
        `The constant sounds of chirping birds is perhaps the most noticeable ambient noise in Forest Hill’s field recording, most likely due to the microphone’s proximity to a leafy bush and evergreen tree. The chatter of pedestrians is also relatively common throughout the recording, as small groups of young people and families waited nearby and checked their phones, perhaps waiting for others to meet them. While the generated soundscape for Forest Hills included sounds of the above ground train entering the nearby station, the train never arrived throughout the during the duration of the recording.`
      ],
      data: [
        `With equal levels of vehicle traffic and greenery, Forest Hills’ soundscape presents an interesting juxtaposition of truck horns blasting and car engines revving with the chirping of birds and chatter of squirrels. While the occasional sound of footsteps indicates a moderate amount of pedestrians in the data, the relatively low number of outdoor cafes keeps the sound clips of a crowd chatting down to a minimum. Careful listeners may also recognize the rumble of a passing above ground train and the voice of its conductor.`
      ],
    }
  },
  {
    neighborhood: "yankee stadium",
    latitude: 40.8282279,
    longitude: -73.926177,
    borough: "bronx",
    folder: '/assets/yankee_stadium/',
    text: {
      field: [
        `The site we chose was in front of Gate 6 of the stadium, halfway down the block from a busy intersection, in the shadow of the subway stop. Maybe it was too cold and windy that day, but there weren't many people around, certainly far less than the data suggested. Mostly there were the sound of car horns, distant construction, and the frequent coming and going of trains. At one point in the recording, a family laughed as they took a selfie together in front of the stadium. Maybe it was an oversight not to include laughter as an ingredient for our data generated soundscapes.`
      ],
      data: [
        `With equal levels of pedestrian traffic and greenery, and no recorded construction projects in the data, the generated soundscape for Yankee Stadium may give listeners the impression of a busy city park. The sounds of passing footsteps and chirping birds are only broken intermittently by the honking of cars and whirr of passing vehicles, representing a low to moderate amount of vehicle traffic in the area. Close listeners will also likely recognize the familiar rumble and squeaking of a passing subway train, and may also catch the muffled thumping of Yankee Stadium’s sole 311 noise complaint, that of loud music from an indoor party.`
      ],
    }
  }
]

export default data